.post-content-tg * p:first-child {
    margin-top: 0
}

.post-content-tg * p:last-child {
    margin-bottom: 0;
}

.post-content-tg * p {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}
