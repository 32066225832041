.MuiCardContent-root {
    padding-top: 0;
    padding-bottom: 0;
}

body {
    background-color: #80aaff;
}

.main {
    background-color: #dfeaff !important;
}
